var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.oldSectMatches.includes(_vm.$route.query.match_type))?_c('gruopManage_all',{attrs:{"match_id":_vm.match_id,"season_id":_vm.season_id,"group_nameS4":_vm.group_nameS4,"type_id":_vm.type_id,"match_type":_vm.match_type,"_id":_vm.s4_id,"stage":_vm.stage}}):_vm._e(),(_vm.oldSectMatches.includes(_vm.$route.query.match_type))?_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"dataForm",staticClass:"demo-ruleForm",attrs:{"label-width":"200px","model":_vm.dataForm,"rules":_vm.rules}},[_c('el-form-item',{staticStyle:{"margin-bottom":"35px"},attrs:{"id":"group_name","label-width":"300px","label":"组别名称","prop":"group_name"}},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"sign-wraper"},[_c('el-input',{ref:"group_name",staticClass:"sign-num",attrs:{"placeholder":"请输入组别"},model:{value:(_vm.dataForm.group_name),callback:function ($$v) {_vm.$set(_vm.dataForm, "group_name", $$v)},expression:"dataForm.group_name"}})],1)])],1),_c('el-form-item',{staticStyle:{"margin-bottom":"35px"},attrs:{"label-width":"300px","label":"队伍管理门派"}},[_c('el-col',{staticClass:"bigcontent",attrs:{"span":24}},[_c('div',{staticClass:"edit-route"},[_c('el-transfer',{staticStyle:{"text-align":"left","display":"inline-block"},attrs:{"filterable":"","filter-method":_vm.filterMethod,"titles":['门派名称-ID', '门派名称-ID'],"format":{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}',
            },"props":{
              key: 'sect_id',
              label: 'sect_name',
            },"data":_vm.all_team_data},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(option.sect_name)+" ")]),_c('span',{staticStyle:{"position":"absolute","left":"268px"}},[_vm._v(_vm._s(option.sect_id))])])}}],null,false,1645872753),model:{value:(_vm.dataForm.valueTeam),callback:function ($$v) {_vm.$set(_vm.dataForm, "valueTeam", $$v)},expression:"dataForm.valueTeam"}},[_c('span',{attrs:{"slot":"left-footer"},slot:"left-footer"},[_vm._v(" 门派名称")]),_c('span',{attrs:{"slot":"left-footer"},slot:"left-footer"},[_vm._v(" 门派ID ")]),_c('span',{attrs:{"slot":"right-footer"},slot:"right-footer"},[_vm._v(" 门派名称 ")]),_c('span',{attrs:{"slot":"right-footer"},slot:"right-footer"},[_vm._v(" 门派ID ")])])],1)])],1),_c('el-form-item',{staticStyle:{"margin-top":"50px,margin:'auto'"}},[_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("取消")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }