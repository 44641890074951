<template>
  <div>
    <!-- s4 -->
    <gruopManage_all
      v-if="!oldSectMatches.includes($route.query.match_type)"
      :match_id ="match_id"
      :season_id="season_id"
      :group_nameS4 = "group_nameS4"
      :type_id = "type_id"
      :match_type="match_type"
      :_id="s4_id"
      :stage="stage"
    ></gruopManage_all>
    <!-- s4之前 -->
    <el-form
      v-if="oldSectMatches.includes($route.query.match_type)"
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <el-form-item
        id="group_name"
        label-width="300px"
        label="组别名称"
        prop="group_name"
        style="margin-bottom: 35px"
      >
        <el-col :span="24">
          <div class="sign-wraper">
            <el-input
              ref="group_name"
              v-model="dataForm.group_name"
              placeholder="请输入组别"
              class="sign-num"
            ></el-input>
            <!-- <div class="minute">&nbsp;组</div> -->
          </div>
        </el-col>
      </el-form-item>
      <el-form-item
        label-width="300px"
        label="队伍管理门派"
        style="margin-bottom: 35px"
      >
        <el-col :span="24" class="bigcontent">
          <div class="edit-route">
            <el-transfer
              style="text-align: left; display: inline-block"
              v-model="dataForm.valueTeam"
              filterable
              :filter-method="filterMethod"
              :titles="['门派名称-ID', '门派名称-ID']"
              :format="{
                noChecked: '${total}',
                hasChecked: '${checked}/${total}',
              }"
              :props="{
                key: 'sect_id',
                label: 'sect_name',
              }"
              :data="all_team_data"
            >
              <span slot="left-footer"> 门派名称</span>
              <span slot="left-footer"> 门派ID </span>
              <span slot="right-footer"> 门派名称 </span>
              <span slot="right-footer"> 门派ID </span>
              <span slot-scope="{ option }">
                <span style="float: left">{{ option.sect_name }} </span>
                <span style="position: absolute; left: 268px">{{
                  option.sect_id
                }}</span>
              </span>
            </el-transfer>
          </div>
        </el-col>
      </el-form-item>
      <!-- 提交，取消 -->
      <el-form-item style="margin-top: 50px,margin:'auto'">
        <el-button
          v-loading="loading"
          type="primary"
          @click="submitForm()"
          size="large"
          >提交</el-button
        >
        <el-button type="primary" @click="goBack()" size="large"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import gruopManage_all from "./components/gruopManage/gruopManage_all.vue";
import {SECT_MATCHES} from '@/utils/Config/GlobalConfig.js'

console.log("SECT_MATCHES", SECT_MATCHES)
export default {
  name: "manager",
  props: {
    view_type: {
      type: String,
    },
  },
  components: {
    gruopManage_all,
  },
  computed: {
    oldSectMatches () {
      return SECT_MATCHES
    },

    isEdit: function () {
      if (this.view_type === "edit") {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      value: [1],
      value4: [1, 2],
      all_team_data: [],
      renderFunc(h, option) {
        return (
          <span>
            {option.key} - {option.label}
          </span>
        );
      },
      s4_id: "",
      season_id: "",
      stage: "",
      loading: false,
      type: "s1",
      group_name: false,
      group_nameS4:'1',
      org_rule: "",
      org_prize: "",
      match_id:"",
      type_id:"",
      match_type:'',
      dataForm: {
        _id: "",
        group_name: "",
        valueTeam: [],
      },
      rules: {
        group_name: [
          {
            required: true,
            message: "请输入组别名称",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  methods: {
    //
    async setFormData() {
      let res = await this.$http.match_subject.new_group_session({
        match_id: this.$route.query.match_id,
        season_id: this.$route.query.season_id,
        group_id:
          this.$route.query.group_id != undefined
            ? this.$route.query.group_id
            : "",
      });

      this.all_team_data = res.data;

      //如果是编辑 拿到对应组的id
      if (this.$route.query.isAdd == "edit") {
        this.dataForm.group_name = this.$route.query.group_name;
        let { data } = await this.$http.match_subject.newsDetail_session({
          group_id: this.$route.query.group_id,
        });
        // this.match_data = data;

        if (data.length !== 0) {
          data.forEach((item) => {
            this.dataForm.valueTeam.push(item.sect_id);
          });
        }
      }
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let tempSect = [];
          if (this.all_team_data.length !== 0) {
            this.all_team_data.forEach((item) => {
              if (this.dataForm.valueTeam.indexOf(item.sect_id) > -1) {
                tempSect.push({
                  sect_id: item.sect_id,
                  sect_name: item.sect_name,
                });
              }
            });
          }

          this.loading = true;

          let { data, errorCode } =
            await this.$http.match_subject.save_season_group({
              _id:
                this.$route.query._id != undefined ? this.$route.query._id : "",
              match_id: this.$route.query.match_id,
              season_id: this.$route.query.season_id,
              group_name: this.dataForm.group_name,
              team_info: tempSect,
            });
          if (errorCode != "0000") {
            return;
          }
          this.loading = false;
          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.goBack();
        }
      });
    },
    goBack() {
      this.removeTabByPath(`/match/subject/gruopLeague`);
      this.$router.push({
        path: `/match/subject/gruopLeague`,
        query: {
          match_id: this.$route.query.match_id,
          season_id: this.$route.query.season_id,
          type_id: this.$route.query.type_id,
          match_type:this.$route.query.match_type
        },
      });
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    filterMethod(query, item) {
      return (
        item.sect_id.indexOf(query) > -1 || item.sect_name.indexOf(query) > -1
      );
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  mounted() {
    this.match_type = this.$route.query.match_type
    this.type_id = this.$route.query.type_id
    this.group_nameS4 = this.$route.query.group_name
    this.match_id = this.$route.query.match_id
    this.s4_id = this.$route.query._id;
    this.season_id = this.$route.query.season_id;
    this.stage = this.$route.query.stage;
    console.log("55555", this.$route.query)
    if (this.$route.query.type_id != "qualifier") {
      this.setFormData();
    }
  },
};
</script>
<style  src="../../editor.css"></style>
<style   scoped>
::-webkit-scrollbar {
  width: 1px;
}
:deep(.el-input ) {
  display: block;
}
.sign-num {
  width: 120px;
}
.sign-wraper {
  display: flex;
  width: 100%;
}
.minute {
  color: rgb(103, 106, 109);
}
.edit-route >>> .el-transfer-panel {
  width: 450px;
}
.edit-route >>> .el-transfer-panel__body {
  height: 410px;
}
.edit-route >>> .el-checkbox-group {
  height: 410px;
  padding-top: 56px;
  padding-bottom: 6px;
}
.edit-route >>> .el-transfer-panel__item {
  position: relative;
  margin-left: 40px;
}

.edit-route >>> .el-transfer-panel__footer {
  width: 450px;
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  align-items: center;
  top: 100px;
  border: 0;
}
</style>
